import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import IconePartecipante from './IconePartecipante';

function ListaPartecipanti({ filteredList, AttivaFirmaCliente, eventCode }) {
  return (
    <>
      {filteredList.map((partecipante) => {
        return (
          <div
            className={`card border-0 text-dark my-3 position-relative overflow-hidden ${
              partecipante.stato === 'confermato' ? 'bg-success text-white' : ''
            }`}
            style={{ borderRadius: '20px' }}
            key={partecipante.id}
          >
            <div className={`card-body`}>
              <h5 className="fw-bold m-0">
                {partecipante.nome} {partecipante.cognome}
                {partecipante.ragioneSociale && (
                  <span className="fw-light">
                    | {partecipante.ragioneSociale}
                  </span>
                )}
              </h5>
              <div className="d-flex align-items-center my-2">
                <div
                  className="bg-white text-dark px-3 py-1 rounded"
                  style={{ marginRight: '1px' }}
                >
                  {partecipante.sessione.nome}
                </div>
                <IconePartecipante filteredList={filteredList} />
              </div>
            </div>
            {partecipante.stato === 'iscritto' ? (
              <a
                className="stretched stretched-link text-white d-flex align-items-center justify-content-center"
                style={{ fontSize: '80px' }}
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target={`#scannerQrCodePartecipante`}
                aria-controls={`scannerQrCodePartecipante`}
                onClick={() => AttivaFirmaCliente(partecipante.codiceQRCode)}
              ></a>
            ) : (
              <Link
                className="stretched stretched-link"
                to={`/anagrafica-partecipante-hostess/${partecipante.id}?eventCode=${eventCode}`}
              ></Link>
            )}
            <div
              className="bordo-colore-gruppo-partecipante"
              style={{ background: `${partecipante.gruppo?.colore}` }}
            ></div>
          </div>
        );
      })}
    </>
  );
}

export default ListaPartecipanti;
