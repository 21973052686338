import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'js-cookie';
import useAuth from '../UseAuth';
import { StateContext } from '../components/StateContainer';
import { Link, useParams } from 'react-router-dom';
import Logout from './components/logout/Logout';
import { SiSessionize as IconSessione } from 'react-icons/si';
import { IoMdMail as IconMail } from 'react-icons/io';
import { IoMdDownload as Download } from 'react-icons/io';
import { IoSearch as Search } from 'react-icons/io5';
import { MdQrCode2 as QrCodeIcon } from 'react-icons/md';
import DocumentoSessioni from './components/documento-scaricare/DocumentoSessioni';
import Moment from 'react-moment';
import ScannerQrCodePartecipante from './components/webcam/ScannerQrCodePartecipante';
import generatePDF from 'react-to-pdf';
import { usePDF } from 'react-to-pdf';
import Loading from '../Partecipante/components/loading/Loading';
import Filtri from './components/filtri/Filtri';
import ListaPartecipanti from './components/partecipante/LIstaPartecipanti';

function DashboardHostess() {
  const {
    loading,
    setLoading,
    error,
    setError,
    setPageTitle,
    eventCode,
    autologinCode,
    AllUserData,
    token,
    sessionId,
    dettagliEvento,
    toPDF,
    params,
  } = useContext(StateContext);

  let dettaglioEventoHostess = Cookies.get('eventDetails');

  const loginUrl = new URL(window.location.href);

  const sessionTitle = loginUrl.searchParams.get('sessionTitle');
  const sessioneData = loginUrl.searchParams.get('sessioneData');

  const formattedDate = sessioneData
    ? moment(sessioneData).format('DD-MM-YYYY')
    : '';

  const { idSessione } = useParams();

  const [codiceValido, setCodiceValido] = useState('');
  const [accendiFotocamera, setAccendiFotocamera] = useState(false);
  const [firmaInserita, setFirmaInserita] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);

  const [searchPartecipante, setSearchPartecipante] = useState('');
  const [dettagliPartecipazione, setDettagliPartecipazione] = useState([]);

  useEffect(() => {
    setPageTitle('Anagrafica completa');
  }, []);

  useEffect(() => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/partecipanti/list`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        sessionId: sessionId || '',
      },
    })
      .then((response) => {
        setDettagliPartecipazione(response.data.response);
        setFirmaInserita(false);
      })
      .catch((error) => {
        if (error.response.status == '401') {
          window.location.replace(
            `/login-hostess?eventCode=${eventCode}&autologinCode=${autologinCode}`
          );
        }
      });
  }, [firmaInserita]);

  const filteredList = dettagliPartecipazione.filter((partecipante) => {
    // Filtraggio per nominativo
    const matchesSearch = searchPartecipante
      ? (partecipante.nome &&
          partecipante.nome
            .toLowerCase()
            .includes(searchPartecipante.toLowerCase())) ||
        (partecipante.cognome &&
          partecipante.cognome
            .toLowerCase()
            .includes(searchPartecipante.toLowerCase())) ||
        (partecipante.ragioneSociale &&
          partecipante.ragioneSociale
            .toString()
            .toLowerCase()
            .includes(searchPartecipante.toLowerCase()))
      : true;

    // Cena
    const cenaSi = params.cenaSi === 'true';
    const cenaNo = params.cenaNo === 'true';

    // Pernottamento
    const pernottamentoSi = params.pernottamentoSi === 'true';
    const pernottamentoNo = params.pernottamentoNo === 'true';

    // Intolleranza
    const intolleranzaSi = params.intolleranzaSi === 'true';
    const intolleranzaNo = params.intolleranzaNo === 'true';

    // Mezzo di trasporto
    const mezzoTrasportoAereo = params.mezzoTrasportoAereo === 'true';
    const mezzoTrasportoTreno = params.mezzoTrasportoTreno === 'true';
    const mezzoTrasportoMacchina = params.mezzoTrasportoMacchina === 'true';

    const sessione = params.sessione;
    const gruppo = params.gruppo;

    // Se nessun filtro è presente o entrambi sono selezionati, mostra tutti
    const matchesFilters =
      (!('cenaSi' in params || 'cenaNo' in params) ||
        cenaSi === cenaNo ||
        (cenaSi && partecipante.cena) ||
        (cenaNo && !partecipante.cena)) &&
      (!('pernottamentoSi' in params || 'pernottamentoNo' in params) ||
        pernottamentoSi === pernottamentoNo ||
        (pernottamentoSi && partecipante.pernottamento) ||
        (pernottamentoNo && !partecipante.pernottamento)) &&
      (!('intolleranzaSi' in params || 'intolleranzaNo' in params) ||
        intolleranzaSi === intolleranzaNo ||
        (intolleranzaSi && partecipante.intolleranzeAlimentari) ||
        (intolleranzaNo && !partecipante.intolleranzeAlimentari)) &&
      (!(
        'mezzoTrasportoAereo' in params ||
        'mezzoTrasportoTreno' in params ||
        'mezzoTrasportoMacchina' in params
      ) ||
        (!mezzoTrasportoAereo &&
          !mezzoTrasportoTreno &&
          !mezzoTrasportoMacchina) ||
        (mezzoTrasportoAereo && partecipante.mezzoTrasporto === 'aereo') ||
        (mezzoTrasportoTreno && partecipante.mezzoTrasporto === 'treno') ||
        (mezzoTrasportoMacchina && partecipante.mezzoTrasporto === 'auto')) &&
      (!sessione || Number(partecipante.sessione?.id) === Number(sessione)) &&
      (!gruppo || Number(partecipante.gruppo?.id) === Number(gruppo));

    return matchesSearch && matchesFilters;
  });

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_PUBLIC_URL}/evento/hostess/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        eventCode: eventCode,
      },
    })
      .then((response) => {
        setLoading(false);
        const eventDetails = response.data.response;
        Cookies.set('eventDetails', JSON.stringify(eventDetails));
        setEventDetails(eventDetails);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const AttivaFirmaCliente = (code) => {
    setAccendiFotocamera(true);
    setCodiceValido(code);
    console.log(code);
  };

  const RichiediListaPartecipanti = () => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/hostess/sessione/download-list`,
      headers: {
        Accept: 'application/pdf', // Modifica per accettare PDF
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        eventCode: eventCode,
        sessionId: sessionId,
      },
      responseType: 'blob', // Importante per gestire il file binario
    })
      .then((response) => {
        // Crea un URL temporaneo per il file
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );

        // Crea un link e simulare un click per scaricare il file
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `lista_partecipazioni_sessione_${sessionId}.xlsx`
        ); // Nome del file
        document.body.appendChild(link);
        link.click();

        // Pulizia
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div
      className="account-pages bg-grey-stellantis"
      style={{
        height: 'auto',
        minHeight: '100vh',
      }}
    >
      <div className="container">
        <div
          className="row"
          style={{
            // minHeight: "100vh",
            height: 'auto',
          }}
        >
          <div
            className="col-12 p-0"
            style={{ height: '100px', marginBottom: '60px' }}
          >
            <div
              className="box-settings-sessioni position-relative"
              style={{ backgroundColor: eventDetails.coloreSecondarioEvento }}
            >
              <Logout />
              <img src={`${eventDetails.logoEvento}`} alt="" />
              <div className="container-nome-evento-sessioni">
                <div
                  className="box-nome-evento-anagrafica-completa"
                  style={{ backgroundColor: eventDetails.colorePrimarioEvento }}
                >
                  {eventDetails.nomeEvento}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ marginBottom: '30px' }}>
            <Filtri />
            {/* <div className="d-flex justify-content-between mt-4 gap-3">
              <Link
                to={`/sessioni-hostess?eventCode=${eventCode}`}
                className={`text-decoration-none border-0 d-flex align-items-center justify-content-between w-75 text-white border-0 p-3 ${
                  !sessionId ? 'w-100' : 'w-75'
                }`}
                style={{
                  borderRadius: '10px',
                  backgroundColor: eventDetails.colorePrimarioEvento,
                }}
              >
                <IconSessione style={{ fontSize: '30px' }} />{' '}
                <span className="text-center">
                  {sessionId
                    ? `${sessionTitle} del ${formattedDate}`
                    : 'Seleziona sessione'}
                </span>
                <span></span>
              </Link>
              <button
                className={`text-white w-25 text-center border-0 ${
                  !sessionId ? 'd-none' : ''
                }`}
                style={{
                  borderRadius: '10px',
                  backgroundColor: eventDetails.colorePrimarioEvento,
                }}
                onClick={RichiediListaPartecipanti}
              >
                <Download style={{ fontSize: '30px' }} />{' '}
              </button>
            </div> */}
          </div>

          <div className="col-12">
            <div className="form form-search mb-3">
              <Search className="fas fa-search" />
              <input
                type="text"
                className="form-control form-input form-input-search px-3"
                placeholder="Cerca nominativo"
                value={searchPartecipante}
                onChange={(e) => setSearchPartecipante(e.target.value)}
              />
            </div>
          </div>

          <div className="col-12">
            {loading ? (
              <div className="overlay-loading d-flex justify-content-center align-items-center">
                <div
                  className="spinner-border spinner-loading text-primary fs-1"
                  role="status"
                ></div>
              </div>
            ) : filteredList.length === 0 ? (
              <div className="col-12">
                <div className="text-center py-2">
                  <p>Nessuna partecipante trovato</p>
                </div>
              </div>
            ) : (
              <ListaPartecipanti
                filteredList={filteredList}
                AttivaFirmaCliente={AttivaFirmaCliente}
                eventCode={eventCode}
              />
            )}
          </div>

          <a
            className="rounded-circle qr-code-button text-white d-flex align-items-center justify-content-center"
            style={{
              fontSize: '80px',
              backgroundColor: eventDetails.colorePrimarioEvento,
            }}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target={`#scannerQrCodePartecipante`}
            aria-controls={`scannerQrCodePartecipante`}
            onClick={() => setAccendiFotocamera(true)}
          >
            <QrCodeIcon />
          </a>
        </div>
      </div>
      <ScannerQrCodePartecipante
        setAccendiFotocamera={setAccendiFotocamera}
        accendiFotocamera={accendiFotocamera}
        codiceValido={codiceValido}
        setCodiceValido={setCodiceValido}
        firmaInserita={firmaInserita}
        setFirmaInserita={setFirmaInserita}
        eventDetails={eventDetails}
      />
    </div>
  );
}

export default DashboardHostess;
