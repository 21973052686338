import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import { FaFilter as Filtro } from 'react-icons/fa';
import { SiSessionize as IconSessione } from 'react-icons/si';
import { BiSolidBriefcaseAlt as Valigia } from 'react-icons/bi';
import { FaUserGroup as IconaGruppi } from 'react-icons/fa6';
import { FaBed as IconaPernottamento } from 'react-icons/fa';
import { ImKey2 as IconaTestDrive } from 'react-icons/im';
import { MdNoFood as IconaIntolleranze } from 'react-icons/md';
import { MdDinnerDining as Cena } from 'react-icons/md';
import { BsFillCarFrontFill as Car } from 'react-icons/bs';
import { FaPlane as Plane } from 'react-icons/fa';
import { FaTrainSubway as Train } from 'react-icons/fa6';

function IconePartecipante({ filteredList }) {
  console.log('filteredList.mezzoTrasporto', filteredList);

  // Controllo mezzo
  let mezzo =
    filteredList[0].mezzoTrasporto === 'aereo' ? (
      <Plane />
    ) : filteredList[0].mezzoTrasporto === 'treno' ? (
      <Train />
    ) : filteredList[0].mezzoTrasporto === 'auto' ? (
      <Car />
    ) : (
      ''
    );

  // Controllo cena
  let cena = filteredList[0].cena ? <Cena /> : '';

  // Pernottamento
  let pernottamento = filteredList[0].pernottamento ? (
    <IconaPernottamento />
  ) : (
    ''
  );

  // Intolleranze
  let intolleranze = filteredList[0].intolleranzeAlimentari ? (
    <IconaIntolleranze />
  ) : (
    ''
  );

  // Test Drive
  let testDrive = filteredList[0].effettuaTestDrive ? <IconaTestDrive /> : '';

  return (
    <>
      {mezzo && <div className="icona-partecipante">{mezzo}</div>}
      {cena && <div className="icona-partecipante">{cena}</div>}
      {pernottamento && (
        <div className="icona-partecipante">{pernottamento}</div>
      )}
      {intolleranze && <div className="icona-partecipante">{intolleranze}</div>}
      {testDrive && <div className="icona-partecipante">{testDrive}</div>}
    </>
  );
}

export default IconePartecipante;
