import React from 'react';
import { Link } from 'react-router-dom';

const OverbookingMessage = ({ idSessione, eventCode, autologinCode }) => {
  let modals = document.getElementsByClassName('modal');
  let modalsBackdrop = document.getElementsByClassName('modal-backdrop');
  const ClickToAnagrafica = () => {
    for (let i = 0; i < modals.length; i++) {
      modals[i].classList.remove('show');
    }
    for (let i = 0; i < modalsBackdrop.length; i++) {
      modalsBackdrop[i].classList.remove('show');
    }
    window.location.replace(
      `/sessioni/${idSessione}/anagrafica-cliente?eventCode=${eventCode}&autologinCode=${autologinCode}`
    );
  };

  // to={`/sessioni/${idSessione}/anagrafica-cliente?eventCode=${eventCode}&autologinCode=${autologinCode}`} data-bs-dismiss="modal"
  return (
    <div
      className="modal fade"
      id="overbookingMessage"
      tabIndex="-1"
      aria-labelledby="overbomodalokingMessage"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div
          className="modal-content border-0 overflow-hidden"
          style={{ borderRadius: '10px 10px 10px 10px' }}
        >
          <div className="modal-header border-0 pb-0">
            <h1
              className="modal-title fs-5 text-blue-stellantis"
              id="overbookingMessage"
            >
              <b>Sessione {idSessione}</b>
              <br />
              Richiesta prenotazione
            </h1>
          </div>
          <div className="modal-body py-0">
            Questa sessione ha già raggiunto il limite di posti disponibili,
            cliccare sul tasto di conferma per richiedere la prenotazione. Nel
            caso si dovessero liberare posti, la sua prenotazione sarà
            confermata via mail.
          </div>
          <div className="d-flex align-items-center overflow-hidden">
            <button
              type="button"
              className="btn text-black bg-grey-stellantis w-50 rounded-0"
              data-bs-dismiss="modal"
            >
              Annulla
            </button>
            <button
              type="button"
              className="btn text-white w-50 bg-green-stellantis rounded-0"
              onClick={ClickToAnagrafica}
            >
              Conferma
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverbookingMessage;
